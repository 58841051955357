import http from "@/http";
import moment from "moment";

const Reports = {
    async payroll(params) {
        params.from = moment(params.from).format("YYYY-MM-DD");
        params.to = moment(params.to).format("YYYY-MM-DD");
        return http('/travels/reports/payroll', {params});
    },

    async bus(params) {
        params.from = moment(params.from).format("YYYY-MM-DD");
        params.to = moment(params.to).format("YYYY-MM-DD");
        return http('/travels/reports/bus', {params});
    },

    async collection(params) {
        params.from = moment(params.from).format("YYYY-MM-DD");
        params.to = moment(params.to).format("YYYY-MM-DD");
        return http('/travels/reports/collection', {params});
    }
};

export default Reports;